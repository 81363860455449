import React, {Component} from 'react';
import style from './fastFacts.module.scss';
import Icon from 'components/icon';

class FastFacts extends Component {
	render() {
		return (
			<div className={`${style.offCanvas} ${this.props.open ? style.open : ''}`}>
				<button className={style.closeIcon} onClick={this.props.handleClick}>
					<Icon icon="close" />
				</button>
				<div className={style.innerFastFacts}>
					<h3>Fast Facts</h3>
					<p>The HPV vaccine can protect your child’s future because it helps prevent 9 types of cancer later in life.</p>
					<ul>
						<li>HPV is a common virus that can cause 9 types of cancer.</li>
						<li>Prevention Matters! HPV cannot be treated, but there is a vaccine that can prevent it.</li>
						<li>The HPV vaccine works best when given between ages 9 and 12, for boys and girls.</li>
						<li>The HPV vaccine is safe, effective, and long-lasting.</li>
						<li>The HPV vaccine series should be completed by age 13. Effectiveness of the vaccine decreases as age at vaccination increases.</li>
					</ul>
					<p>HPV vaccination can prevent more than 90% of HPV cancers when given at the recommended ages.</p>
					<h4>Who should get vaccinated?</h4>
					<p><strong>9-12</strong><br />HPV vaccination works best when given between ages 9 and 12. Children and young adults ages 13 through 26 who have not been vaccinated, or who haven't gotten all their doses, should get the vaccine as soon as possible. </p>
					<p>The vaccine is given in two shots, with 6 to 12 months between shots.*<br /><em>*3 shots of the HPV vaccine are needed for children who started that vaccine at age 15 or older, up to age 26.</em></p>
				</div>
			</div>
		)
	}
}

export default FastFacts;