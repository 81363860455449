import React, { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

import BackgroundImage from 'gatsby-background-image'

const Background = (props) => {
	const data = useStaticQuery(graphql`
		query {
			allFile( filter: {internal: {mediaType: { regex: "ima?ge?s?/" } } } ) {
				nodes {
					relativePath
					childImageSharp {
						fluid(quality: 90, maxWidth: 1920) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		}
	`);
	let imagePath = (props.image ? props.image.replace(/^\//, '') : '');
	const match = useMemo(() => (
		data.allFile.nodes.find(({ relativePath }) => imagePath === relativePath)
	), [ data, imagePath ]);
	const placeholder = useMemo(() => (
		data.allFile.nodes.find(({ relativePath }) => 'placeholder.jpg' === relativePath)
	), [ data ]);
	const imageData = [match ? match.childImageSharp.fluid : placeholder.childImageSharp.fluid];
	if (props.overlay) {
		imageData.push(props.overlay);
	}
	return (
		<BackgroundImage
			Tag="div"
			className={props.className}
			fluid={imageData.reverse()}
			{...props}
		>{props.children}</BackgroundImage>
	)
}

const StyledBackground = styled(Background)`
	width: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
`

export default StyledBackground