/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { parse } from 'node-html-parser';
import { Location } from '@reach/router';


function isMetaSet(name, meta) {
	for (let i=0; i<meta.length; i++){
		if (meta[i].name === name)return true;
		if (meta[i].property === name)return true;
	}
	return false;
}

function deleteMeta(names, meta) {
	if (!Array.isArray(names))names = names.split(',');
	return meta.filter((item) => names.indexOf(item.name) === -1 && names.indexOf(item.property) === -1);
}


function SEO({ children, description, lang, meta, title, schema, image, location }) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
						siteUrl
					}
				}
			}
		`
	)
	if (typeof meta === 'string') {
		const root = parse(meta);
		meta = [];
		root.querySelectorAll('meta').forEach((node) => {
			meta.push(node.attributes);
		});
	}
	if (!Array.isArray(meta))meta = [];
	let descriptionSet = false;
	meta.forEach((item) => {
		if (item.name === 'description')descriptionSet = true;
		if (item.name === 'image' || item.name === 'og:image' || item.name === 'twitter:image'){
			image = meta[item];
		}
	});
	if (image === undefined)image = `${location.origin||site.siteMetadata.siteUrl}/share-image.jpg`;
	if (image) {
		meta = deleteMeta(['image'], meta);
		meta.push({
			name: 'image',
			content: image,
		});
	}
	if (description) {
		meta = deleteMeta(['description', 'og:description', 'twitter:description'], meta);
		descriptionSet = true;
		meta.push({
			name: 'description',
			content: description,
		});
	}
	if (!descriptionSet && site.siteMetadata.description) {
		meta.push({
			name: 'description',
			content: site.siteMetadata.description,
		});
	}
	meta.forEach((item) => {
		if (item.name === 'description'){
			if (!isMetaSet('og:description', meta)){
				meta.push({
					property: 'og:description',
					content: item.content,
				});
			}
			if (!isMetaSet('twitter:description', meta)){
				meta.push({
					name: 'twitter:description',
					content: item.content,
				});
			}
		}
		if (item.name === 'image') {
			if (!isMetaSet('og:image', meta)) {
				meta.push({
					property: 'og:image',
					content: item.content,
				})
			}
			if (!isMetaSet('twitter:image', meta)) {
				meta.push({
					name: 'twitter:image',
					content: item.content,
				})
			}
		}
	});
	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			defaultTitle={site.siteMetadata.title}
			title={title}
			titleTemplate={`%s | ${site.siteMetadata.title}`}
			meta={[
				{
					property: 'og:url',
					content: location.href,
				},
				{
					property: `og:title`,
					content: title,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata.author,
				},
				{
					name: `twitter:title`,
					content: title,
				},
			].concat(meta)}
		> <script key="schema" type="application/ld+json">{JSON.stringify(schema)}</script> { children } </Helmet>
	)
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
}

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	// meta: PropTypes.arrayOf(function(propValue, key, componentName, location, propFullName){
	// 	if (propValue typeof !== 'object' && propValue typeof !== 'string')return new Error(`Invalid prop ${propFullName} supplied to ${componentName}. Type must be object or string.`);
	// }),
}


export default props => (
	<Location>
		{locationProps => <SEO location={locationProps.location} {...props}>{props.children}</SEO>}
	</Location>
);

// export default SEO