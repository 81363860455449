import React, {Component} from 'react';
import style from './faqSlider.module.scss';
import Slider from 'react-slick';
import Icon from 'components/icon';

function NextArrow(props) {
	const { onClick } = props;
	return (
		<div
			className={`${style.nextArrow} ${style.slideArrow}`}
			onClick={onClick}
			role="presentation"
		>Next</div>
	);
}

function PrevArrow(props) {
	const { onClick } = props;
	return (
		<div
			className={`${style.prevArrow} ${style.slideArrow}`}
			onClick={onClick}
			role="presentation"
		>Previous</div>
	);
}

class FaqSlider extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentSlide: this.props.slide,
		}
	}
	componentDidMount() {
		this.numSlides = this.props.faqs.length;
	}
	componentDidUpdate() {
		this.slider.slickGoTo(this.props.slide);
	}
	render() {
		const slick = {
			dots: false,
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			className: style.slick,
			nextArrow: <NextArrow />,
			prevArrow: <PrevArrow />,
			beforeChange: (prev, next) => {
				this.setState({ currentSlide: next });
			},
		}
		return (
			<div className={`${style.offCanvas} ${this.props.open ? style.open : ''} ${this.props.orientation ? style.isLandscape : ''}`}>
				<button className={style.closeIcon} onClick={this.props.handleClick}>
					<Icon icon="close" />
				</button>
				<Slider ref={slider => (this.slider = slider)} {...slick}>
					{this.props.faqs.map((faq, index) =>
						<div key={index} className={style.slide}>
							<p className={style.question}>{faq.question}</p>
							<p className={style.answer}>{faq.answer}</p>
						</div>
					)}
				</Slider>
				<p className={style.counter}>{this.state.currentSlide + 1} of {this.numSlides}</p>
			</div>
		)
	}
}

export default FaqSlider;