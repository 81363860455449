import React, { useState, useEffect, useRef, useCallback } from "react"
import Image from 'shared/image';
import Layout from 'components/layout/basic';
import Icon from 'components/icon';
import style from './home.module.scss';
import Background from 'shared/background';
import FaqSlider from 'components/accordion/faqSlider';
import FastFacts from 'components/fastFacts';
import { Trail, Spring } from 'react-spring/renderprops';
import SEO from 'shared/seo';

const calcDynamicHeight = objectWidth => {
	const vw = window.innerWidth;
	const vh = window.innerHeight;
	return objectWidth - vw + vh;
};

const handleDynamicHeight = (ref, setDynamicHeight) => {
	const objectWidth = ref.current.scrollWidth;
	const dynamicHeight = calcDynamicHeight(objectWidth);
	setDynamicHeight(dynamicHeight);
};

const applyScrollListener = (objectRef, containerRef, setTranslateX, setPage) => {
	var objectWidth = objectRef.current.scrollWidth;
	window.addEventListener("resize", function(){
		objectWidth = objectRef.current.scrollWidth;
		snapToPage(objectWidth);
	});
	function between(x, min, max) {
		return x >= min && x <= max;
	}
	function determinePage(objectWidth) {
		const offsetTop = containerRef.current.offsetTop;
		const quarter = objectWidth*.25;
		const half = objectWidth*.5;
		const threeQuarter = objectWidth*.75;
		switch(true) {
			case (between(offsetTop, 0, quarter)):
				if (offsetTop - 0 < quarter - offsetTop) {
					setPage(1);
				} else {
					setPage(2);
				}
				break;
			case (between(offsetTop, quarter, half)):
				if (offsetTop - quarter < half - offsetTop) {
					setPage(2);
				} else {
					setPage(3);
				}
				break;
			case (between(offsetTop, half, threeQuarter)):
				if (offsetTop - half < threeQuarter - offsetTop) {
					setPage(3);
				} else {
					setPage(4);
				}
				break;
			default:
				break;
		}
	}
	function snapToPage(objectWidth) {
		const offsetTop = containerRef.current.offsetTop;
		const quarter = objectWidth*.25;
		const half = objectWidth*.5;
		const threeQuarter = objectWidth*.75;
		switch(true) {
			case (between(offsetTop, 0, quarter)):
				if (offsetTop - 0 < quarter - offsetTop) {
					window.scrollTo({
						top: 0,
						left: 0,
						behavior: 'smooth'
					});
				} else {
					window.scrollTo({
						top: quarter,
						left: 0,
						behavior: 'smooth'
					});
				}
				break;
			case (between(offsetTop, quarter, half)):
				if (offsetTop - quarter < half - offsetTop) {
					window.scrollTo({
						top: quarter,
						left: 0,
						behavior: 'smooth'
					});
				} else {
					window.scrollTo({
						top: half,
						left: 0,
						behavior: 'smooth'
					});
				}
				break;
			case (between(offsetTop, half, threeQuarter)):
				if (offsetTop - half < threeQuarter - offsetTop) {
					window.scrollTo({
						top: half,
						left: 0,
						behavior: 'smooth'
					});
				} else {
					window.scrollTo({
						top: threeQuarter,
						left: 0,
						behavior: 'smooth'
					});
				}
				break;
			default:
				break;
		}
	}
	var timer;
	window.addEventListener("scroll", () => {
		const offsetTop = -containerRef.current.offsetTop;
		setTranslateX(offsetTop);
		determinePage(objectWidth);
		window.clearTimeout( timer );
	    timer = setTimeout(function() {
	    	snapToPage(objectWidth);
	    }, 250);
	}, true);
};

export default () => {

	const [dynamicHeight, setDynamicHeight] = useState(null);
	const [translateX, setTranslateX] = useState(0);
	const [page, setPage] = useState(1);
	const [faqOpen, setFaqOpen] = useState(false);
	const [faqSlide, setFaqSlide] = useState(0);
	const [factsOpen, setFactsOpen] = useState(false);
	const [windowWidth, setWindowWidth] = useState(null);
	const [windowScroll, setWindowScroll] = useState(0);
	const [scrollDir, setScrollDir] = useState(0);
	const [aspectRatio, setAspectRatio] = useState();

	// console.log(page);
	const containerRef = useRef(null);
	const objectRef = useRef(null);
	// const fastFactsRef = useRef(null);
	const page1Ref = useRef(null);
	const page2Ref = useRef(null);
	const page3Ref = useRef(null);
	const page4Ref = useRef(null);

	const handleSlides = () => {
		if (faqOpen === false) {
			setFaqOpen(true);
		} else {
			setFaqOpen(false);
		}
	}

	const setSlide = (index) => {
		setFaqSlide(index);
		if (faqOpen === false) {
			setFaqOpen(true);
			window.dataLayer = window.dataLayer || [];
			var event = {
				event: `FAQ #${index + 1}`,
				action: 'click',
				type: 'page interaction',
				category: 'FAQs',
			};
			window.dataLayer.push(event);
		} else {
			setFaqOpen(false);
		}
	}

	const handleFacts = () => {
		if (factsOpen === false) {
			setFactsOpen(true);
			window.dataLayer = window.dataLayer || [];
			var event = {
				event: 'fastFacts',
				action: 'click',
				type: 'page interaction'
			};
			window.dataLayer.push(event);
		} else {
			setFactsOpen(false);
		}
	}

	const resizeHandler = () => {
		handleDynamicHeight(objectRef, setDynamicHeight);
	};

	const windowWidthHandler = () => {
		setWindowWidth(window.innerWidth);
	}

	const windowScrollHandler = () => {
		setWindowScroll(containerRef.current.scrollTop);
	}

	const between = (x, min, max) => {
		return x >= min && x <= max;
	}

	const determineRatio = useCallback(
		() => {
			const width = window.innerWidth;
			const height = window.innerHeight;
			const ratio = height/width;
			setAspectRatio(ratio.toFixed(2));
		},
		[],
	);

	const mobilePageHandler = useCallback(
		() => {
			const scrollTop = containerRef.current.scrollTop;
			switch (true) {
				case (between(scrollTop, 0, page2Ref.current.offsetTop)):
					if (scrollTop - 0 < page2Ref.current.offsetTop - scrollTop) {
						setPage(1);
					} else {
						setPage(2);
					}
				break;
				case (between(scrollTop, page2Ref.current.offsetTop, page3Ref.current.offsetTop)):
					if (scrollTop - page2Ref.current.offsetTop < page3Ref.current.offsetTop - scrollTop) {
						setPage(2);
					} else {
						setPage(3);
					}
				break;
				case (between(scrollTop, page3Ref.current.offsetTop, page4Ref.current.offsetTop)):
					const viewPortHalf = containerRef.current.clientHeight/2;
					if (scrollTop > scrollDir) {
						// scrolling down
						if (between(scrollTop, page3Ref.current.offsetTop, page3Ref.current.offsetTop+viewPortHalf)) {
							setPage(3);
						} else if (between(scrollTop, page4Ref.current.offsetTop-viewPortHalf, page4Ref.current.offsetTop)) {
							setPage(4);
						}
					} else if (scrollTop < scrollDir) {
						// scrolling up
						if (between(scrollTop, page3Ref.current.offsetTop, page4Ref.current.offsetTop-viewPortHalf)) {
							setPage(3);
						}
					}
				break;
				default:
					break;
			};
			setScrollDir(scrollTop);
		},
		[scrollDir],
	);

	const scrollHome = (event) => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
		if(isMobile) {
			containerRef.current.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});
		}
		window.dataLayer = window.dataLayer || [];
		var event = {
			event: `logo click`,
			action: 'click',
			type: 'page interaction',
		};
		window.dataLayer.push(event);
	}

	const selectPage = (page) => {
		setPage(page);
		window.dataLayer = window.dataLayer || [];
		var event = {
			event: `page ${page}`,
			action: 'click',
			type: 'page interaction',
			category: `page selector`,
		};
		window.dataLayer.push(event);
		let ref;
		switch(page) {
			case 1:
				ref = page1Ref;
				break;
			case 2:
				ref = page2Ref;
				break;
			case 3:
				ref = page3Ref;
				break;
			case 4:
				ref = page4Ref;
				break;
			default:
				ref = page1Ref;
		}
		if(ref.current) {
			const offsetTop = ref.current.offsetLeft;
			window.scrollTo({
				top: offsetTop,
				left: 0,
				behavior: 'smooth'
			});
		}
	}

	const isMobile = windowWidth <= 1023;
	const isLandscape = aspectRatio <= .49;

	
	// Force push

	useEffect(() => {
		setWindowWidth(window.innerWidth);
		determineRatio();
		if(!isMobile) {
			handleDynamicHeight(objectRef, setDynamicHeight);
			window.addEventListener("resize", resizeHandler, setWindowWidth);
			applyScrollListener(objectRef, containerRef, setTranslateX, setPage);
		}
		window.addEventListener("resize", windowWidthHandler);
		window.addEventListener("resize", determineRatio);
		const mobileScroll = containerRef.current;
		mobileScroll.addEventListener('scroll', windowScrollHandler);
		if(isMobile) {
			mobileScroll.addEventListener('scroll', mobilePageHandler);
		}
		if(!isMobile) {
			return () => {
				window.removeEventListener("resize", resizeHandler, setWindowWidth);
				window.removeEventListener("resize", windowWidthHandler);
				window.removeEventListener("resize", determineRatio);
				mobileScroll.removeEventListener('scroll', windowScrollHandler);
				mobileScroll.removeEventListener('scroll', mobilePageHandler);
			};
		} else {
			return () => {
				window.removeEventListener("resize", windowWidthHandler);
				window.removeEventListener("resize", determineRatio);
				mobileScroll.removeEventListener('scroll', windowScrollHandler);
				mobileScroll.removeEventListener('scroll', mobilePageHandler);
			};
		}
	}, [isMobile, mobilePageHandler, determineRatio]);

	const faqs = [
		{
			id: 0,
			question: 'Why does my child need the HPV vaccine?',
			answer: 'The human papillomavirus (HPV) vaccine protects against cancers caused by HPV infection. HPV is a common virus that infects teens and adults. About 14 million people, including teens, become infected with HPV each year. Half, or 7 million, of these people fall between the ages of 15 and 24. HPV infection can cause cervical, vaginal and vulvar cancers in women and penile cancer in men. HPV can also cause anal cancer, cancer of the back of the throat (oropharynx), and genital warts in both men and women.',
		},
		{
			id: 1,
			question: 'When should my child be vaccinated?',
			answer: 'All kids who are 9 to 12 years of age should get two shots of the HPV vaccine six to twelve months apart. Getting vaccinated on time protects preteens well before ever being exposed to the virus. People get HPV from another person during intimate sexual or skin to skin contact.\n\nSome children may need three doses of the HPV vaccine. For example, adolescents who receive their two shots less than six months apart will require a third dose for best protection. Also, children who start the vaccine series on or after their 15th birthday will need three shots given over 6 months. If your teen hasn’t gotten the vaccine yet, talk to his/her doctor about scheduling an appointment as soon as possible. HPV vaccination is also recommended for everyone through 26 years of age who haven’t been vaccinated already.\n\nThe best way to remember to get your child all of the recommended doses is to make an appointment for the remaining shots before you leave the doctor’s office or clinic.',
		},
		{
			id: 2,
			question: 'Does HPV vaccination prevent cancer?',
			answer: 'Human papillomavirus (HPV) infects about 14 million people, including teens, each year. While most HPV infections go away on their own, infections that don\'t go away can lead to certain types of cancer. Every year, close to 36,000 men and women develop a cancer caused by HPV. The HPV vaccination could prevent more than 90% of these cancers from ever developing.',
		},
		{
			id: 3,
			question: 'Is HPV vaccination safe for boys and girls?',
			answer: 'With over 100 million doses distributed in the United States, the HPV vaccine has a reassuring safety record that is backed by over 10 years of monitoring and research. All vaccines used in the United States are required to go through years of extensive safety testing before they are licensed. Once in use, public health officials continuously monitor their safety and effectiveness.\n\nSince the Food and Drug Administration (FDA) licensed the vaccine, scientists and vaccine researchers have conducted large research studies to monitor and evaluate safety. Over 100 studies in millions of people worldwide show that the HPV vaccine is safe and the benefits of the vaccine far outweigh any possible side effects or risks.',
		},
		{
			id: 4,
			question: 'Does HPV vaccination work?',
			answer: 'The HPV vaccine has been extremely effective since it was introduced over 10 years ago. The HPV vaccine produces antibodies that fight the virus and provides strong and long lasting protection. Infections with HPV types that cause most HPV cancers and genital warts have dropped 71% among teen girls. Cervical precancers caused by HPV has dropped by 40% by those women who receive the HPV vaccine.',
		},
		{
			id: 5,
			question: 'Does HPV vaccination provide long-lasting protection?',
			answer: 'Studies suggest that the protection provided by the HPV vaccine is long lasting. Studies have followed people who received the HPV vaccine for over 10 years, and protection has remained high in those individuals with no evidence of weakened protection over time. Scientists believe the vaccine is effective and research continues to determine the long lasting effects in protection from the virus.',
		},
		{
			id: 6,
			question: 'Does HPV vaccination cause side effects?',
			answer: 'Like any vaccine or medicine, the HPV vaccination can cause side effects. The most common side effects are mild and include pain, redness, or swelling in the arm where the shot is given; dizziness, fainting, nausea, and headache. Fainting after any vaccine, including the HPV vaccine, is rare but can happen. To prevent fainting and injuries related to fainting, anyone receiving the HPV vaccine should be seated or lying down during vaccination and remain in that position for 15 minutes after the vaccine is given. The benefits of the HPV vaccination far outweigh any potential risk of side effects.',
		}
	];

	const pagers = [1,2,3,4];

	const SEOimage = 'share-image.jpg';

	var schema = {
		"@context": "http://schema.org",
		"@type": "MedicalTherapy",
		"name": "HPV Vaccine",
	}

	return (
		<>
			<Layout>
				<section className={`${style.header} ${windowScroll >=1 ? style.scrolled : ''}`}>
					<div className="grid-container">
						<div className="grid-x grid-margin-x">
							<div className="cell small-8 medium-6 text-left">
								<div onClick={scrollHome} onKeyDown={scrollHome} role="presentation">
									<Icon className={`${style.logo} show-for-medium`} icon="logo" />
									<Icon className={`${style.logo} hide-for-medium`} icon="logoMobile" />
								</div>
							</div>
							<div className={`cell small-4 medium-6 text-right`}>
								<div className={style.fastFactsIconHolder} onClick={handleFacts} onKeyDown={handleFacts} aria-label="Fast Facts" role="presentation">
									<Icon className={style.fastFacts} icon="clipboard"/>
								</div>
							</div>
						</div>
					</div>
				</section>
				<div className={`${style.horizontalSection} ${isLandscape && !isMobile ? style.isLandscape : ''}`}>
					<div className={style.tallOuterContainer} style={{ height: `${dynamicHeight}px` }}>
						<div className={style.stickyInnerContainer} ref={containerRef}>
							<div className={style.horizontalTranslateContainer} style={{ transform: `translateX(${translateX}px)` }} ref={objectRef}>
								<div className={style.cardsContainer}>
									<div ref={page1Ref} className={`${style.card} ${style.landing}`}>
										<Background className={style.background} image="landing-BG.jpg">
											<div className={`${style.innerCard} grid-container`}>
												<div className="grid-x grid-margin-x">
													<Spring
														from={{
															transform: page === 1 ? 'translate3d(0,-40px,0)' : 'translate3d(0,0px,0)',  
															opacity: page === 1 ? 0 : 1
														}} 
														to={{
															transform: page === 1 ? 'translate3d(0,0px,0)' : 'translate3d(0,-40px,0)',  
															opacity: page === 1 ? 1 :0
														}}
														delay={500}>
														{props => <div style={props} className="cell small-12 large-8">
															<Image className={style.shieldThem} src="shieldthem.png" alt="Shield Them For Life" />
														</div>}
													</Spring>
												</div>
											</div>
											<div className={style.children}>
												<Spring
													from={{
														transform: page === 1 ? 'translate3d(-40px,0,0)' : 'translate3d(0px,0,0)',  
														opacity: page === 1 ? 0 : 1
													}} 
													to={{
														transform: page === 1 ? 'translate3d(0px,0,0)' : 'translate3d(-40px,0,0)',  
														opacity: page === 1 ? 1 :0
													}}
													delay={900}>
													{props => <div style={props} className={`${style.boy} show-for-large`}>
														<Image src="boy.png" alt="Boy" />
													</div>}
												</Spring>
												<Spring
													from={{
														transform: page === 1 ? 'translate3d(40px,0,0)' : 'translate3d(0px,0,0)',  
														opacity: page === 1 ? 0 : 1
													}} 
													to={{
														transform: page === 1 ? 'translate3d(0px,0,0)' : 'translate3d(40px,0,0)',  
														opacity: page === 1 ? 1 :0
													}}
													delay={700}>
													{props => <div style={props} className={style.girl}>
														<Image className="show-for-medium" src="girl.png" alt="Girl" />
														<Image className="hide-for-medium" src="girl-mobile.png" alt="Girl" />
													</div>}
												</Spring>
											</div>
										</Background>
									</div>
									<div ref={page2Ref} className={`${style.card} ${style.page2}`}>
										<Background className={style.background} image="pagetwo-BG2.jpg">
											<div className={`${style.innerCard} grid-container`}>
												<div className="grid-x grid-margin-x">
													<div className="cell small-12">
														<Spring
															from={{
																transform: page === 2 ? 'translate3d(0,-40px,0)' : 'translate3d(0,0px,0)',  
																opacity: page === 2 ? 0 : 1
															}} 
															to={{
																transform: page === 2 ? 'translate3d(0,0px,0)' : 'translate3d(0,-40px,0)',  
																opacity: page === 2 ? 1 :0
															}}>
																{props => <div style={props} className={style.shieldThem}><Image src="shieldthem.png" alt="Shield Them For Life" /></div>}
														</Spring>
														<Spring
															from={{
																transform: page === 2 ? 'translate3d(0,-40px,0)' : 'translate3d(0,0px,0)',  
																opacity: page === 2 ? 0 : 1
															}} 
															to={{
																transform: page === 2 ? 'translate3d(0,0px,0)' : 'translate3d(0,-40px,0)',  
																opacity: page === 2 ? 1 :0
															}}
															delay={200}>
																{props => <h2 style={props}>The Not-So-Secret Weapon That <br />Protects Forever</h2>}
														</Spring>
													</div>
												</div>
											</div>
										</Background>
									</div>
									<div ref={page3Ref} className={`${style.card} ${style.page3}`}>
										<div className={`${style.innerCard}`}>
											<div className="grid-x grid-margin-x">
												<Spring
													from={{
														transform: page === 3 ? 'translate3d(0,-40px,0)' : 'translate3d(0,0px,0)',  
														opacity: page === 3 ? 0 : 1
													}} 
													to={{
														transform: page === 3 ? 'translate3d(0,0px,0)' : 'translate3d(0,-40px,0)',  
														opacity: page === 3 ? 1 :0
													}}>
													{props => <div style={props} className={`${style.leftHalf} cell small-12 large-6`}>
														<h3>HPV, or <span>Human Papillomavirus</span>, is a common virus that can cause 9 types of cancer. While there is no treatment for HPV, there is a vaccine that can prevent it.</h3>
														<div className="grid-x">
															<div className="cell small-8 medium-6">
																<p>HPV Cancers Include:</p>
																<ul>
																	<li>Cervical</li>
																	<li>Vaginal</li>
																	<li>Vulvar</li>
																	<li>Anal</li>
																	<li>Penile</li>
																	<li>Head and Neck</li>
																</ul>
															</div>
															<div className="cell small-4 medium-6">
																<Image className={style.nine} src="nine.png" alt="9" />
															</div>
														</div>
													</div>}
												</Spring>
												<div className={`${style.rightHalf} cell small-12 large-6`}>
													<div className={`${style.verticalGrid} grid-y`}>
														<Spring
															from={{
																transform: page === 3 ? 'translate3d(0,-40px,0)' : 'translate3d(0,0px,0)',  
																opacity: page === 3 ? 0 : 1
															}} 
															to={{
																transform: page === 3 ? 'translate3d(0,0px,0)' : 'translate3d(0,-40px,0)',  
																opacity: page === 3 ? 1 :0
															}}
															delay={200}>
															{props => <div style={props} className={`${style.topBlock} cell large-6`}>
																<Icon className={style.lightBulb} icon="lightbulb" />
																<p>If your son or daughter is 9 years or older, talk to their doctor about the HPV vaccine. The vaccine is safe, effective, and long-lasting.</p>
															</div>}
														</Spring>
														<Spring
															from={{
																transform: page === 3 ? 'translate3d(0,-60px,0)' : 'translate3d(0,0px,0)',  
																opacity: page === 3 ? 0 : 1
															}} 
															to={{
																transform: page === 3 ? 'translate3d(0,0px,0)' : 'translate3d(0,-60px,0)',  
																opacity: page === 3 ? 1 :0
															}}
															delay={300}>
															{props => <div style={props} className={`${style.bottomBlock} cell large-6`}>
																<Icon className={style.shield} icon="shield" />
																<p>Vaccinating your child at the recommended ages can help keep them healthy well into adulthood and is the best way to prevent HPV cancers later in life.</p>
															</div>}
														</Spring>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div ref={page4Ref} id="page4" className={`${style.card} ${style.page4}`}>
										<Background className={style.background} image="pagefour-BG2.jpg">
											<div className={`${style.innerCard} grid-container`}>
												<div className="grid-x grid-margin-x">
													<div className="cell small-12 medium-8 medium-offset-2">
														<div className={`${style.accordionContainer}`}>
															<Trail 
																items={faqs} 
																keys={item => item.id} 
																from={{
																	transform: page === 4 ? 'translate3d(0,-40px,0)' : 'translate3d(0,0px,0)',  
																	opacity: page === 4 ? 0 : 1
																}} 
																to={{
																	transform: page === 4 ? 'translate3d(0,0px,0)' : 'translate3d(0,-40px,0)',  
																	opacity: page === 4 ? 1 :0
																}}
															>
																{(item, index) => props => {
																	return (
																		<div style={props} className={style.question} onClick={() => setSlide(index)} role="presentation">
																			<p>{item.question}</p><Icon className={style.plus} icon="plus" />
																		</div>
																	)
																}}
															</Trail>
														</div>
													</div>
												</div>
											</div>
										</Background>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<section className={`${style.pagerContainer} show-for-large`}>
					<div className="grid-container">
						<div className="grid-x grid-margin-x">
							<div className="cell large-1" />
							<Trail 
								items={pagers} 
								keys={item => item} 
								from={{
									transform: 'translate3d(-40px,-40px,0)',
									opacity: 0,
								}} 
								to={{
									transform: 'translate3d(0px,0px,0)',
									opacity: 1,
								}}
							>
								{(item, index) => props => {
									return (
										<div style={props} onClick={() => selectPage(item)} onKeyDown={() => selectPage(item)} role="presentation" className={`${style.pagerInner} cell auto`}>
											<div className={`${style.pager} ${page === item ? style.active : ''}`} />
										</div>
									)
								}}
							</Trail>
							<div className="cell large-1" />
						</div>
					</div>
				</section>
				<FaqSlider open={faqOpen} slide={faqSlide} faqs={faqs} handleClick={handleSlides} orientation={isLandscape} />
				<FastFacts open={factsOpen} handleClick={handleFacts} orientation={isLandscape} />
			</Layout>
			<SEO title={`HPV Vaccine`} description={`Talk to your doctor today about the HPV Vaccine. The Not-So-Secret-Weapon that protects forever.`} image={SEOimage} schema={schema} />
		</>
	)
}