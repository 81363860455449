import React, {Component} from "react"
import PropTypes from "prop-types"
// Import neccessary site components, try to maintain alphabetical order
import SEO from 'shared/seo';

export default class Basic extends Component {

	render() {
		return (
			<>
			<SEO />
			<main>{this.props.children}</main>
			</>
		)
	}
}

Basic.propTypes = {
	children: PropTypes.node.isRequired,
}