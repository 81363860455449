import React, { useMemo } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = (props) => {
	const data = useStaticQuery(graphql`
		query {
			allFile( filter: {internal: {mediaType: { regex: "ima?ge?s?/" } } } ) {
				nodes {
					relativePath
					childImageSharp {
						fluid(quality: 90, maxWidth: 1920) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		}
	`);
	let imagePath = (props.src ? props.src.replace(/^\//, '') : '');
	const match = useMemo(() => (
		data.allFile.nodes.find(({ relativePath }) => imagePath === relativePath)
	), [ data, imagePath ]);
	const placeholder = useMemo(() => (
		data.allFile.nodes.find(({ relativePath }) => 'placeholder.jpg' === relativePath)
	), [ data ]);
	const imageData = [match ? match.childImageSharp.fluid : placeholder.childImageSharp.fluid];
	const alt = props.alt||'';
	const className = props.className||'';
	return <Img className={className} fluid={imageData} alt={alt} />
}

export default Image
